.page-peoples {
  .people-summary {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    .people-image {
      height: 60px;
      width: 60px;
      margin-right: 10px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 60px;
      }
    }
    .people-meta {
      flex: 1;
      h2 {
        margin: 0;
        font-size: 18px;
        font-weight: normal;
      }
      p {
        color: $black;
        text-transform: uppercase;
        margin: 0;
        font-size: 12px;
        font-weight: 500;
      }
    }
    .people-content {
      margin-top: 20px;
      flex: 1 0 100%;
    }
  }

  .people-summary-large {
    background-color: $white-offset;
    padding: 30px;
    border-radius: 3px;
    .people-image {
      height: 90px;
      width: 90px;
      margin-right: 20px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 90px;
      }
    }
  }
}
